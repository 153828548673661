import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../../context/context";
import { MdCalendarToday, MdLocalPhone, MdLocationOn } from "react-icons/md";
import { IoMdClock } from "react-icons/io";
import { ButtonContent } from "../boton/ButtonContent";
import Navmenu from "../MenuList";
import { GiHamburgerMenu } from "react-icons/gi";
import IconsRedes from "../IconRedes";

const Header_8 = () => {
  const { rpdata } = useContext(GlobalDataContext);
  const [isOpen, setOpen] = useState(false);
  return (
    <header className="w-full  flex flex-col items-center">
      <section className="w-full flex items-center justify-center gap-10 flex-wrap p-5 bg1 text-white">
        {rpdata?.dbPrincipal?.workdays.slice(0, 1).map((item, index) => {
          return (
            <li key={index} className="py-2 items-center justify-center flex">
              <div className="flex">
                <div className="flex items-center pr-5">
                  <MdCalendarToday size={20} />
                  <span className="pl-3">{item.day}</span>
                </div>
                <div className="flex items-center pr-5">
                  <IoMdClock size={20} />
                  <span className="pl-3">
                    {rpdata?.dbPrincipal?.workHours[index].hour}
                  </span>
                </div>
              </div>
            </li>
          );
        })}
        {rpdata?.dbPrincipal?.location?.slice(0, 1).map((item, index) => {
          return (
            <div key={index} className="flex items-center">
              <MdLocationOn className="text-[25px] rounded-md" />
              <span className="pl-3">{item.address}</span>
            </div>
          );
        })}
      </section>
      <section className="w-full flex justify-center gap-5 flex-wrap">
        <div className="flex items-center">
          <ButtonContent />
        </div>
        <img
          src={rpdata?.dbPrincipal?.logo}
          alt="company_logo"
          className="w-[320px]"
        />
        <div className="flex flex-col justify-center">
          {rpdata?.dbPrincipal?.phones?.slice(0, 2).map((item, index) => {
            return (
              <div key={index} className="flex items-center">
                <a
                  href={`tel:+1${item.phone}`}
                  className="py-2 flex items-center"
                >
                  <MdLocalPhone className="text-[40px]" />
                  <div className="flex flex-col">
                    <span className="pl-3 font-extrabold">Call Us</span>
                    <span className="pl-3">{item.phone}</span>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </section>
      <nav className="w-full flex justify-center p-5 bg1">
          <div className="relative hidden md:block">
            <Navmenu classes={'text-white'} />
          </div>
          <div
            className='md:hidden flex justify-center py-1 rounded-md w-[100px] h-[40px] bg-white'
            onClick={() => setOpen(true)}
          >
            <GiHamburgerMenu className="w-full h-full" />
          </div>
        </nav>


        {isOpen ? (
        <div className="fixed z-50 top-0 right-0 left-0 bg-white w-full h-screen overflow-y-auto pb-10">
          <div className="flex justify-end w-[90%] mx-auto relative">
            <button
              className="font-bold text-[20px] mt-5"
              onClick={() => setOpen(false)}
            >
              X
            </button>
          </div>
          <div className="w-[60%] mx-auto pb-10">
            <img
              src={rpdata?.dbPrincipal?.logo}
              alt="logo"
              loading="lazy"
              className="w-full"
            />
          </div>
          <div className="w-4/5 mx-auto ">
            <Navmenu classes="flex-col gap-3" />
          </div>

            <div className="flex flex-col items-center mt-28">
              <span className="font-bold">Follow Us On:</span>
            <IconsRedes classes="flex justify-center gap-5 text-black"/>
            </div>
        </div>
      ) : null}
    </header>
  );
};

export default Header_8;
