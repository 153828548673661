import React, {useState} from "react";
import Stars from "./stars";
const ChamberReviews = () =>{

    const [isMore, setMore] = useState(false)
    const handleClick = () => {
        return setMore(!isMore)
    }
        
    const reviewsChamb = [
        {
            name:"Joyce L L",
            date:"4/29/2023",
            text:"Again Leo's Tree & Lawn did a fantastic job at my home. They power washed my home. EVERYTHING shines. They cut down a tree, including getting rid of the stump. They also cleaned my yard for Spring and added mulch. All beautifully done. Leo, himself, either works with his staff or comes by later to make sure the work is complete and done perfectly. This company is dependable, reasonable and does an excellent job. They also clean up when done.",
            stars:5
        },
        {
            name:"M R",
            date:"11/13/2022",
            text:"Excellent service. Called for estimate. Leo was helpful with suggestions. Came next day. Work was done fast, completed as explained. Clean-up excellent. The work was done by experts. Reasonable, friendly and excellent work.",
            stars:5
        },
        {
            name:"",
            date:"10/22/2022",
            text:"Thanks a lot! Thank you Leo's Tree and Lawn Service for the excellent work and service. I appreciate your help and appreciate how well you did your job. I can tell you guys are passionate about what you do and provide the best customer service and work. I heartily endorse your business.",
            stars:5
        },
        {
            name:"",
            date:"10/11/2022",
            text:"Leo's Tree and Lawn Service came out to my home and did two jobs for me. The first job was done in such a professional and thorough manner that I booked them for a second job the next week. They just completed the second job and once again, they did an awesome job. Very nice and hardworking crew! Thanks Leonard for the great work.",
            stars:5
        },
        {
            name:"",
            date:"10/3/2022",
            text:"My parents had the Leo's Tree and Lawn Service Inc team dealing with major tree work. I would recommend this company to anyone. They are Well-organised and professional from the first quote, planning, advising, and most importantly in the carrying out of the job.",
            stars:5
        },
        {
            name:"makumba",
            date:"9/2/2022",
            text:"por un buen jeffe",
            stars:5
        },
        {
            name:"",
            date:"8/12/2022",
            text:"he price for the work was fair and just. I compared their prices with others and they definitely were out of the top. Their prices are reasonable , even my mom who has actual business with them is reassuring me that Leo's Tree and Lawn Service is a high quality landscaping and lawn service company. Highly recommend it.",
            stars:5
        },
        {
            name:"",
            date:"8/5/2022",
            text:"Excellent in everything you would want... knowledgeable professionals that brought huge value to my home's landscape. Safety AND Insured, which in itself is high on my list for high risk job: rappelling, chainsaws, etc. Look no further.. hands down THE BEST tree service. The crew works to please and is well run. Experienced Great Work.",
            stars:5
        },
        {
            name:"",
            date:"7/27/2022",
            text:"We had a great experience working with this company. It's how I would imagine a good experience with a tree service would be. They showed up when they said they would both for the quote and doing the work. Easy and consistent communication, quality work.",
            stars:5
        },
        {
            name:"",
            date:"7/25/2022",
            text:"We are getting referrals from friends and family who have actual business with Leo's Tree and Lawn Service and we learned that they had a lot of good reviews. Will definitely hire them very soon.",
            stars:5
        },
        {
            name:"",
            date:"7/17/2022",
            text:"I am one of the lucky people who witness the professionalism of Leo's Tree and Lawn Service Inc when I am at my friend’s house. I saw how they work fast, super friendly crew and accommodating. Their professionalism is unmatched. Highly recommend it.",
            stars:5
        },
        {
            name:"",
            date:"5/27/2022",
            text:"We saw this company Leo's Tree and Lawn Service Inc from my neighbor's house, and we saw the service of this company. We have observed it to be fast and very orderly. Even my friends had remarked that they was very good and professional.",
            stars:5
        },
        {
            name:"Deborah A",
            date:"5/18/2022",
            text:"Best job ever. Amazing work from Leo and all his workers.",
            stars:5
        },
        {
            name:"",
            date:"5/5/2022",
            text:"Leonardo's crew arrived on time and was extremely professional and friendly. Leo's Tree and Lawn Service Inc crew are the best. Outstanding attitude and customer service from everyone. I recommend Leo's Tree and Lawn Service Inc!",
            stars:5
        },
        {
            name:"Hector G",
            date:"4/10/2022",
            text:"Very nice guys. Leo’s tree and lawn services has been working for us for over 10 years. They are the best on this industry",
            stars:5
        },
        {
            name:"Mercedes r",
            date:"4/10/2022",
            text:"They always do a nice job!!",
            stars:5
        },
        {
            name:"CBM",
            date:"4/10/2022",
            text:"Always very helpful- GREAT crew- very reliable",
            stars:5
        },
        {
            name:"Josh J",
            date:"3/24/2022",
            text:"I am a property manager for a private estate in Westchester County. I have known Leo for many years. In that time I have come to depend on him for many things. My needs have ranged from tree care to lawn care to garden installation to masonry and to masonry and even carpentry.. He and his crew met or exceeded my expectations with every request. His is not the cheapest company to work with but as the saying goess you get what you pay for in .. I highly recommend Leo's Tree & Lawn Service for any of your property needs.",
            stars:5
        },
        {
            name:"Alexandra R",
            date:"3/9/2022",
            text:"Always doing a great job. They are nice and clean",
            stars:5
        },
        {
            name:"Brian C",
            date:"3/9/2022",
            text:"Leo is a true professional. He knows what to do and his amazing crew gets it done safely with great attention to detail. We use Leo for all of our big landscaping projects and tree trimming and removal. His service record is simply the best.",
            stars:5
        },
        {
            name:"Tom C",
            date:"3/9/2022",
            text:"Awesome service !",
            stars:5
        },
        {
            name:"Mike C",
            date:"3/9/2022",
            text:"Leo has been “my guy” for years now for trees, lawn, and plowing - through two properties. Always reliable and there to help quickly in emergencies. I often recommend him to friends in the area without hesitation.",
            stars:5
        },
        {
            name:"Kristina L",
            date:"3/9/2022",
            text:"Leo and his crew are truly extraordinary! Their tree and lawn work is meticulous and their stone work magnificent. They plow snow promptly and carefully. Leo is a doll - always cheerful and as helpful as can be. I don’t think there’s anything he can’t do. He’s made our lives luxurious and we can’t recommend him highly enough. !",
            stars:5
        },
        {
            name:"ARRY M",
            date:"9/16/2021",
            text:"Leo is a wonderful person and his quality of work is second to none. His staff are very help and knowledgeable. Leo does not treat you like a client, he treats you as a friend, with helpful suggestions and ideas to get the best product. When we do planting or tree cutting, he is alway present to supervise his workers. I will definitely recommend Leo's Tree & Lawd Service to anyone.",
            stars:5
        },
        {
            name:"Andrene",
            date:"9/16/2021",
            text:"I have been using Leo for the past 4 years. Service is exceptional and the work is always good. Leo takes the time to explain what needs to be done and works with you until satisfied.",
            stars:5
        },
        {
            name:"Denis P",
            date:"9/13/2021",
            text:"The best company in westchester ny. Always on time and very responsable!",
            stars:5
        },
        {
            name:"Christopher E",
            date:"9/3/2021",
            text:"wonderful service!",
            stars:5
        },
        {
            name:"Bobbie Mc C",
            date:"8/18/2021",
            text:"Do you need tree work done? Call Leo. He did a great job! at our house.. Good Work and Good Price.",
            stars:5
        },
        {
            name:"Sebastian B",
            date:"7/20/2021",
            text:"Very good service. I would 100% recommend him.",
            stars:5
        },
        {
            name:"",
            date:"7/17/2021",
            text:"Good job",
            stars:5
        },
        {
            name:"Kevin Q",
            date:"7/17/2021",
            text:"Good job",
            stars:5
        },
        {
            name:"Alexis c",
            date:"7/17/2021",
            text:"Good Job",
            stars:5
        },
        {
            name:"Richard W S",
            date:"7/14/2021",
            text:"The best Trustworthy Exceptional",
            stars:5
        },
    ]

    return(
        <section className="w-full py-20">          
                {
                    isMore?
                    reviewsChamb.map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    
                                    <span className="font-medium">{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>

                        </section>
                        )
                    })
                    :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium" >{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                <button className="bg-red-500 px-5 py-2 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button>
        </section> 
    );

}

export default ChamberReviews